import React, { useRef, useState } from 'react'
import { requisitionsRequests } from '@src/api/requisitions'
import SideBar from '@src/components/SideBar/SideBar'
import { useTable } from '@src/components/Table/hooks'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { Button, chain, Side } from '@revolut/ui-kit'
import {
  RequisitionGroup,
  LoadingRequisitionGroup,
} from '@src/features/JobPostingFlow/components/RequisitionGroup'
import { RequisitionItem } from '@src/features/JobPostingFlow/components/RequisitionItem'
import { JobPostingSpecialisationInterface } from '@src/interfaces/jobPosting'

interface ConnectRequisitionsSidebarProps {
  requisitions: RequisitionInterface[]
  specialisation: JobPostingSpecialisationInterface
  onClose: VoidFunction
  onConnectRequisitions: (newConnectedRequisitions: RequisitionInterface[]) => void
}

export const ConnectRequisitionsSidebar = ({
  requisitions,
  specialisation,
  onClose,
  onConnectRequisitions,
}: ConnectRequisitionsSidebarProps) => {
  const [selectedRequisitionIds, setSelectedRequisitionIds] = useState<number[]>(
    requisitions.map(({ id }) => id),
  )
  const scrollRef = useRef<HTMLDivElement>(null)
  const table = useTable<RequisitionInterface>(
    requisitionsRequests,
    [
      {
        filters: [
          {
            name: String(specialisation.id),
            id: specialisation.id,
          },
        ],
        columnName: 'specialisation__id',
        nonResettable: true,
      },
    ],
    [
      {
        sortBy: 'specialisation',
        direction: SORT_DIRECTION.ASC,
        nonResettable: true,
      },
    ],
  )
  const tableRequisitionIds = table.data.map(({ id }) => id)
  const allSelected = tableRequisitionIds.every(id => selectedRequisitionIds.includes(id))
  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedRequisitionIds([])
    } else {
      setSelectedRequisitionIds(tableRequisitionIds)
    }
  }
  const handleSelectRequisition = (requisitionId: number) => {
    if (selectedRequisitionIds.includes(requisitionId)) {
      const newSelectedRequisitionIds = selectedRequisitionIds.filter(
        id => id !== requisitionId,
      )
      setSelectedRequisitionIds(newSelectedRequisitionIds)
    } else {
      setSelectedRequisitionIds([...selectedRequisitionIds, requisitionId])
    }
  }
  return (
    <SideBar
      isOpen
      title={`${specialisation.name} requisitions`}
      onClose={onClose}
      sideProps={{ scrollRef }}
    >
      {table.loading ? (
        <LoadingRequisitionGroup />
      ) : (
        <RequisitionGroup selected={allSelected} onClick={handleSelectAll}>
          {table.data.map(requisition => (
            <RequisitionItem
              key={requisition.id}
              requisition={requisition}
              selected={selectedRequisitionIds.includes(requisition.id)}
              onClick={() => {
                handleSelectRequisition(requisition.id)
              }}
            />
          ))}
        </RequisitionGroup>
      )}
      <Side.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            const newConnectedRequisition = table.data.filter(({ id }) =>
              selectedRequisitionIds.includes(id),
            )
            onConnectRequisitions(newConnectedRequisition)
          }}
        >
          {chain('Connect', selectedRequisitionIds.length)}
        </Button>
      </Side.Actions>
    </SideBar>
  )
}
