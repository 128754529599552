import React, { PropsWithChildren } from 'react'
import {
  Box,
  chain,
  Checkbox,
  HStack,
  Item,
  ItemSkeleton,
  Subheader,
  TextSkeleton,
  Widget,
} from '@revolut/ui-kit'

export const LoadingRequisitionGroup = () => {
  return (
    <Box>
      <Subheader>
        <Subheader.Title>
          <HStack>
            {chain(<TextSkeleton width="s-72" />, <TextSkeleton width="s-72" />)}
          </HStack>
        </Subheader.Title>
      </Subheader>
      <Widget>
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </Widget>
    </Box>
  )
}

interface RequisitionGroupProps {
  selected: boolean
  onClick: VoidFunction
}

export const RequisitionGroup = ({
  selected,
  onClick,
  children,
}: PropsWithChildren<RequisitionGroupProps>) => {
  return (
    <Widget>
      <Item
        use="label"
        onClick={e => {
          e.preventDefault()
          onClick()
        }}
      >
        <Item.Prefix>
          <Checkbox checked={selected} />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>{selected ? 'Deselect all' : 'Select all'}</Item.Title>
        </Item.Content>
      </Item>
      {children}
    </Widget>
  )
}
