import React from 'react'
import Table from '@components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useTable } from '@components/TableV2/hooks'
import Stat from '@components/Stat/Stat'
import { EmployeeInterface } from '@src/interfaces/employees'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { getGrowthPlansRelations } from '@src/api/growthPlans'
import { GrowthPlanRelationInterface } from '@src/interfaces/growthPlans'
import { getRow } from '@src/features/GrowthPlans/EmployeePlans/Row'

interface Props {
  employee: EmployeeInterface
  navigation?: React.ReactElement
}

const row = getRow()

export const EmployeePlansTable = ({ navigation }: Props) => {
  const table = useTable({ getItems: params => getGrowthPlansRelations(params) }, [])

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat label="Growth plans" val={table.loading ? undefined : table.count} />
      </Table.Widget.Info>
      <Table.Widget.Filters>{navigation && navigation}</Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<GrowthPlanRelationInterface>
          name={TableNames.EmployeeGrowthPlans}
          row={row}
          hideCount
          {...table}
          emptyState={<EmptyTableRaw title="Growth plans will appear here." />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
