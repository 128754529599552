import React, { useEffect, useState } from 'react'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import { Route, Switch, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { pathToUrl } from '@src/utils/router'
import { Flex } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import Hierarchy from './Hierarchy'
import TalentTab from '@src/pages/Forms/CommonTalentTab/Talent'
import { TalentType } from '@src/interfaces/talent/talent'
import { getEmployeeWithManagerFilter } from '@src/api/employees'
import { Hiring } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/components/Hiring'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { DropdownNavigation } from '@src/components/DropdownNavigation/DropdownNavigation'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { useIsGrowthPlansEnabled } from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'
import { GrowthPlans } from '@src/pages/EmployeeProfile/Preview/Talent/GrowthPlans'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
}

export const Talent = ({ stats, data }: Props) => {
  const permissions = useSelector(selectPermissions)
  const params = useParams<{ id: string }>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const canViewGrowthPlans = useIsGrowthPlansEnabled()

  const [functionalManagerPermissions, setFunctionalManagerPermissions] =
    useState<string[]>()

  useEffect(() => {
    getEmployeeWithManagerFilter(data.id, 'quality_control__id').then(res =>
      setFunctionalManagerPermissions(res.data.field_options.permissions),
    )
  }, [])

  const isNewLayout = useIsNewLayout()
  const isNewTable = useIsNewTable()

  const tabs = [
    {
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.DIRECT_REPORTS,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.DIRECT_REPORTS,
        params,
      ),
      title: 'Direct reports',
      component: TalentTab,
      talentType: TalentType.DirectReport,
      canView: true,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.GROWTH_PLANS,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.GROWTH_PLANS,
        params,
      ),
      title: 'Growth Plans',
      component: GrowthPlans,
      canView: canViewGrowthPlans,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.FUNCTIONAL_REPORTS,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.FUNCTIONAL_REPORTS,
        params,
      ),
      title: 'Functional reports',
      component: TalentTab,
      talentType: TalentType.FunctionalReport,
      canView: performanceSettings?.enable_functional_management,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.HIERARCHY,
      to: pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.HIERARCHY,
        params,
      ),
      title: 'Hierarchy',
      component: Hierarchy,
      canView: !isNewLayout,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.HIRING,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.HIRING, params),
      title: 'Hiring',
      component: Hiring,
      canView: isNewLayout && permissions.includes(PermissionTypes.ViewRequisitions),
    },
  ]

  const filteredTabs = tabs.filter(tab => tab.canView)
  const navigation = (
    <Flex alignItems="flex-start" mb="s-24">
      <TabBarNavigation
        isSubtab
        tabs={filteredTabs}
        key={stats?.kpi_performance_percent}
      />
    </Flex>
  )

  return (
    <>
      {!isNewTable && !isNewLayout && navigation}
      <Switch>
        {filteredTabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component
              data={
                tab.talentType === TalentType.FunctionalReport
                  ? {
                      ...data,
                      field_options: {
                        ...data.field_options,
                        permissions: functionalManagerPermissions,
                      },
                    }
                  : data
              }
              talentType={tab.talentType!}
              navigation={
                isNewTable ? <DropdownNavigation options={filteredTabs} /> : navigation
              }
            />
          </Route>
        ))}
        <InternalRedirect to={filteredTabs[0].path} />
      </Switch>
    </>
  )
}
