import React from 'react'
import { abbreviate, Avatar, DualAvatar, Status } from '@revolut/ui-kit'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { HeaderActions } from './HeaderActions'
import { formatRRule } from '@src/utils/format'
import { getAvatarUrl } from '@src/utils/employees'
import { ROUTES } from '@src/constants/routes'
import { OneToOneMeeting } from '@src/interfaces/meetings'

interface Props {
  data?: OneToOneMeeting
  isLoading: boolean
  onChangeSchedule: VoidFunction
}

export const SummaryHeader = ({ data, isLoading, onChangeSchedule }: Props) => {
  if (!data && !isLoading) {
    return null
  }

  const title = `1:1 ${data?.manager.name} <> ${data?.employee.name}`
  const status = formatRRule(data?.meeting_series?.recurrence)

  const isDualAvatar = data?.manager.avatar && data?.employee.avatar

  return (
    <PageHeader
      isLoading={isLoading}
      title={
        <PageHeader.Title
          avatar={
            isDualAvatar ? (
              <DualAvatar
                topImage={getAvatarUrl(data?.manager.avatar)}
                bottomImage={getAvatarUrl(data?.employee.avatar)}
              />
            ) : data?.manager.full_name ? (
              <Avatar uuid="kUclRY" label={abbreviate(data.manager.full_name)} />
            ) : null
          }
          labels={<Status useIcon="Calendar">{status}</Status>}
          title={title}
          actions={<HeaderActions onChangeSchedule={onChangeSchedule} />}
        />
      }
      backUrl={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.OVERVIEW}
    />
  )
}
