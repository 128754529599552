import {
  GetRequestInterface,
  RequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  OneToOneMeeting,
  FreeBusyCalendar,
  GoogleCalendarEventAttendee,
  MeetingCompanionInterface,
  MeetingEvent,
  MeetingInterface,
  MeetingWithCompanionDetail,
  MeetingWithManagersStats,
  OneToOneMeetingsStats,
  MeetingTemplateInterface,
  TimeSlot,
  MeetingNotesFeedback,
} from '@src/interfaces/meetings'
import { useFetch, usePost, useUpdate } from '@src/utils/reactQuery'
import { getCommentsAPI } from '@src/api/comments'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'

export const getMeetingCompanionsRequests = (
  employeeId: number,
): RequestInterface<MeetingCompanionInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.ONE_TO_ONES}/${employeeId}/allRelationsWithMeetings`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id =>
    api.get(`${API.ONE_TO_ONES}/${employeeId}/allRelationsWithMeetings/${id}`),
  patchItem: async (data, id) =>
    api.patch(`${API.ONE_TO_ONES}/${employeeId}/allRelationsWithMeetings/${id}`, data),
  deleteItem: async id =>
    api.delete(`${API.ONE_TO_ONES}/${employeeId}/allRelationsWithMeetings/${id}`),
  postItem: async data =>
    api.post(`${API.ONE_TO_ONES}/${employeeId}/allRelationsWithMeetings`, data),
})

export const getMeetingsWithCompanionRequests = (
  employeeId: number | string, // employee at whose page the data is requested
  companionId: number | string, // employee with whom the meetings are
): RequestInterface<MeetingInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithRelationEmployee`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
  getItem: async id =>
    api.get(
      `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithRelationEmployee/${id}`,
    ),
  patchItem: async (data, id) =>
    api.patch(
      `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithRelationEmployee/${id}`,
      data,
    ),
  deleteItem: async id =>
    api.delete(
      `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithRelationEmployee/${id}`,
    ),
  postItem: async data =>
    api.post(
      `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithRelationEmployee`,
      data,
    ),
})

export const useGetManagerMeetingsStats = (employeeId: number) => {
  return useFetch<MeetingWithManagersStats>(
    `${API.ONE_TO_ONES}/${employeeId}/meetingsWithManagers/stats`,
  )
}

export const useGetCompanionMeetingsDetails = (
  employeeId: number | string,
  companionId: number | string,
) => {
  return useFetch<MeetingWithCompanionDetail>(
    `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithDetailEmployee/detail`,
  )
}

export const submitCalendarTracking = (syncNow = false) => {
  return api.post(
    `${API.ONE_TO_ONES}/newMeetingsForEmployee/fetch`,
    syncNow ? { sync_now: true } : {},
  )
}

export const companyMeetingsRequests: TableRequestInterface<
  OneToOneMeeting,
  OneToOneMeetingsStats
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.MEETINGS}/managerMeetings`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v1',
    ),
  getStats: async ({ filters }) =>
    api.get(
      `${API.MEETINGS}/managerMeetings/stats`,
      {
        params: filterSortPageIntoQuery(undefined, filters),
      },
      'v1',
    ),
}

export const templateRequests: TableRequestInterface<MeetingTemplateInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.FEEDBACK_TEMPLATES}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const meetingsTemplatesFormRequests: RequestInterfaceNew<MeetingTemplateInterface> =
  {
    delete: async ({ id }) => api.delete(`${API.FEEDBACK_TEMPLATES}/${id}`),
    get: async ({ id }) => api.get(`${API.FEEDBACK_TEMPLATES}/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.FEEDBACK_TEMPLATES}/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(API.FEEDBACK_TEMPLATES, {
        ...data,
        audiences: data.audiences || [],
        items: data.items || [],
      }),
  }

export const getOneToOneCommentsApi = (meetingId: number) =>
  getCommentsAPI({ baseUrl: `${API.ONE_TO_ONES}/${meetingId}/comments` })

export const useGetEmployeeMeeting = (
  employeeId: number | string,
  meetingId: number | string,
) =>
  useFetch<OneToOneMeeting>({
    url: `${API.MEETINGS}/employeeManagerMeetings/${employeeId}/${meetingId}`,
  })

export const getEmployeeMeetings = (
  id: number,
  { sortBy, filters, page }: FetchDataQueryInterface,
): AxiosPromise<GetRequestInterface<OneToOneMeeting>> =>
  api.get(`${API.MEETINGS}/employeeManagerMeetings/${id}`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export enum OneToOneMeetingsQueryKeys {
  EmployeeId = 'employee_id',
  ManagerId = 'manager_id',
  EmployeeFullName = 'employee__full_name',
  ManagerFullName = 'manager__full_name',
  EmployeeRelationType = 'employee_relation_type',
  RelationType = 'relation_type',
  ManagerTeamDepartmentId = 'manager__team__department_id',
  ManagerTeamDepartmentName = 'manager__team__department__name',
  NoMeeting = 'is_missing_meeting',
}

export enum MeetingTemplatesQueryKeys {
  CreatedBy = 'created_by',
  Frequency = 'frequency',
  TemplateName = 'name',
  Duration = 'duration',
}

export enum OneToOneMeetingsFiltersQueryKeys {
  ParticipantId = 'participant_id',
  ParticipantFullName = 'participant__full_name',
  // searching by following fields: manager__full_name, employee__full_name
  Search = 'search',
}

export interface EmployeeMeetingEventsParams {
  employee_id: number
  start?: string
  // employee1.id,employee2.id,...employeeN.id
  attendees?: string
  is_assigned?: boolean
  is_series?: boolean
}

export const useGetEmployeeMeetingEvents = (params: EmployeeMeetingEventsParams) =>
  useFetch<{ results: MeetingEvent<GoogleCalendarEventAttendee>[] }>({
    url: API.MEETINGS_TRACKER,
    params: { params },
  })

export const useGetMeetingNotesFeedback = (id?: number) =>
  useFetch<MeetingNotesFeedback>({
    url: `${API.FEEDBACK_ONE_TO_ONE_NOTES}/${id}/meetingFeedback`,
    version: 'v1',
    withoutHandling: true,
    queryOptions: {
      enabled: Boolean(id),
    },
  })

export const deleteMeetingNotesFeedback = (meetingId: number, noteId: number) =>
  api.delete<MeetingNotesFeedback>(
    `${API.FEEDBACK_ONE_TO_ONE_NOTES}/${meetingId}/${noteId}`,
  )

export interface EmployeeFreeBusySlotsParams {
  time_min: string
  time_max: string
  employee_ids: string
}

export const useGetEmployeeFreeBusySlots = (params: EmployeeFreeBusySlotsParams) =>
  useFetch<FreeBusyCalendar>({
    url: `${API.MEETINGS}/slots/freebusy`,
    params: { params },
  })

export const useGetMeetingTemplate = (id: string) =>
  useFetch<MeetingTemplateInterface>({
    url: `${API.FEEDBACK_TEMPLATES}/${id}`,
  })

export interface ScheduleOneToOneMeetingPayload extends TimeSlot {
  recurrence: string[]
  title: string
}

export const useScheduleOneToOneMeeting = (
  employeeId: number | string,
  meetingId: number | string,
) =>
  usePost<OneToOneMeeting, undefined, ScheduleOneToOneMeetingPayload>({
    url: `${API.MEETINGS}/employeeManagerMeetings/${employeeId}/${meetingId}/createSeries`,
    withoutHandling: true,
  })

export const useLinkOneToOneMeeting = (employeeId: number | string) =>
  useUpdate<OneToOneMeeting, { meeting_series: { id: number } }>(
    `${API.MEETINGS}/employeeManagerMeetings/${employeeId}`,
    'v1',
    undefined,
    false,
    (_oldData, newData) => newData,
    true,
  )
