import React from 'react'
import { Button, Details, Group, Header, Image, Popup } from '@revolut/ui-kit'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import {
  ScheduleOneToOneMeetingPayload,
  useScheduleOneToOneMeeting,
} from '@src/api/meetings'
import { formatDate } from '@src/utils/format'
import {
  formatRecurrence,
  formatTimeDiff,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/formatters/schedulingEvent'
import { useScheduleStatusPopup } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/hooks/useScheduleStatusPopup'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

interface Props {
  isOpen: boolean
  title: string
  description?: string
  meeting: OneToOneMeeting
  event: ScheduleOneToOneMeetingPayload
  handleClose: VoidFunction
}

export const SchedulePopup = ({
  isOpen,
  title,
  description,
  meeting,
  event,
  handleClose,
}: Props) => {
  const { mutateAsync: scheduleOneToOne, isLoading } = useScheduleOneToOneMeeting(
    meeting.manager.id,
    meeting.id,
  )

  const handleReviewMeeting = () => {
    navigateTo(
      pathToUrl(
        ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.ONE_TO_ONE.SUMMARY,
        { id: meeting.employee.id },
      ),
    )
  }

  // TODO: provide "handleConfirm" callback once extension is published to the store https://revolut.atlassian.net/browse/REVC-6613
  const { showSuccessPopup, showErrorPopup } = useScheduleStatusPopup(
    handleReviewMeeting,
    handleReviewMeeting,
  )

  const handleConfirm = async () => {
    try {
      await scheduleOneToOne(event)
      showSuccessPopup(event)
    } catch (err) {
      showErrorPopup(err)
    } finally {
      handleClose()
    }
  }

  return (
    <Popup size="sm" open={isOpen}>
      <Header variant="bottom-sheet">
        <Header.Title>{title}</Header.Title>
        <Header.Actions>
          <Image size={48} src="https://assets.revolut.com/assets/apps/Google.png" />
        </Header.Actions>
        <Header.Description>{description}</Header.Description>
      </Header>
      <Group px="s-16">
        <Details>
          <Details.Title>Frequency</Details.Title>
          <Details.Content>{formatRecurrence(event)}</Details.Content>
        </Details>
        <Details>
          <Details.Title>Time</Details.Title>
          <Details.Content>{formatTimeDiff(event)}</Details.Content>
        </Details>
        <Details>
          <Details.Title>Start date</Details.Title>
          <Details.Content>{formatDate(event.start)}</Details.Content>
        </Details>
      </Group>
      <Popup.Actions>
        <Button onClick={handleConfirm} pending={isLoading}>
          Schedule
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
