export interface NotificationInterface {
  id: string
  open?: boolean
  backUrl?: string
  value: React.ReactNode
  message?: string
  duration: number
  type: NotificationTypes
}

export enum NotificationTypes {
  success = 'success',
  warning = 'warning',
  /** @deprecated */
  error = 'error',
  info = 'info',
}

export interface NotificationsState {
  readonly notifications: NotificationInterface[]
}
