import React, { useMemo } from 'react'
import {
  MeetingTemplateInterface,
  TemplateItemInterface,
  TemplateItemRole,
} from '@src/interfaces/meetings'
import {
  ActionButton,
  chain,
  DetailsCellSkeleton,
  DetailsSkeleton,
  Flex,
  Group,
  ItemSkeleton,
  List,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { PreviewSectionHeader } from './PreviewSectionHeader'
import { MeetingTemplateEditStep } from '../interfaces'

interface Props {
  data?: MeetingTemplateInterface
  isLoading: boolean
  onEdit: (step: MeetingTemplateEditStep) => void
}

const Skeleton = () => {
  return (
    <Widget p="s-16">
      <ItemSkeleton />

      <DetailsCellSkeleton>
        <DetailsSkeleton.Title />
        <DetailsSkeleton.Content />
      </DetailsCellSkeleton>

      <DetailsCellSkeleton>
        <DetailsSkeleton.Title />
        <DetailsSkeleton.Content />
      </DetailsCellSkeleton>

      <DetailsCellSkeleton>
        <DetailsSkeleton.Title />
        <DetailsSkeleton.Content />
      </DetailsCellSkeleton>

      <DetailsCellSkeleton>
        <DetailsSkeleton.Title />
        <DetailsSkeleton.Content />
      </DetailsCellSkeleton>
    </Widget>
  )
}

export const PreviewFieldsSection = ({ data, isLoading, onEdit }: Props) => {
  const itemValuesByRoleRecord = useMemo(() => {
    return data?.items.reduce<Record<string, TemplateItemInterface>>((acc, item) => {
      acc[item.role] = item
      return acc
    }, {})
  }, [data?.items])

  const isMeetingDurationSettings =
    itemValuesByRoleRecord?.[TemplateItemRole.ActionItems] ||
    itemValuesByRoleRecord?.[TemplateItemRole.PerformanceRating] ||
    itemValuesByRoleRecord?.[TemplateItemRole.PersonalNotes]

  if (isLoading) {
    return <Skeleton />
  }

  if (!data?.items.length) {
    return null
  }

  const contentItems =
    typeof itemValuesByRoleRecord?.[TemplateItemRole.TalkingPoints]?.content === 'string'
      ? [itemValuesByRoleRecord?.[TemplateItemRole.TalkingPoints]?.content]
      : itemValuesByRoleRecord?.[TemplateItemRole.TalkingPoints]?.content

  return (
    <Widget p="s-16">
      <PreviewSectionHeader
        title={data?.items?.length ? chain('Fields', data?.items.length) : 'Fields'}
        subtitle="Which fields do you want to capture for each meeting"
        iconName="Message"
        actionElement={<ActionButton onClick={() => onEdit('fields')}>Edit</ActionButton>}
      />

      {itemValuesByRoleRecord?.[TemplateItemRole.TalkingPoints] && (
        <VStack pt="s-16">
          <Text pb="s-12" color={Token.color.greyTone50} variant="emphasis2">
            Before meeting
          </Text>
          <Group p="s-16">
            <PreviewSectionHeader
              title="Talking Points"
              subtitle={
                <Flex gap="s-8" flexDirection="column">
                  {chain('Text field', 'List')}
                  <List variant="compact">
                    {contentItems?.map((contentItem, index) => {
                      return (
                        <List.Item key={index} useIcon="Dot">
                          <Text color={Token.color.greyTone50}>{contentItem}</Text>
                        </List.Item>
                      )
                    })}
                  </List>
                </Flex>
              }
              iconName="Form"
            />
          </Group>
        </VStack>
      )}

      {isMeetingDurationSettings && (
        <VStack pt="s-16">
          <Text pb="s-12" color={Token.color.greyTone50} variant="emphasis2">
            During/after meeting
          </Text>
          <VStack gap="s-8">
            {itemValuesByRoleRecord?.[TemplateItemRole.ActionItems] && (
              <Widget p="s-16">
                <PreviewSectionHeader
                  title="Action items"
                  subtitle={chain('Text field', 'Check list')}
                  iconName="Questionnaire"
                />
              </Widget>
            )}

            {itemValuesByRoleRecord?.[TemplateItemRole.PerformanceRating] && (
              <Group p="s-16">
                <PreviewSectionHeader
                  title="Performance Rating"
                  subtitle={
                    <Flex flexDirection="column">
                      {chain('Text field', 'Check list')}
                      <Text>Personal field, will not be shared with others</Text>
                    </Flex>
                  }
                  iconName="Trophy"
                />
              </Group>
            )}

            {itemValuesByRoleRecord?.[TemplateItemRole.PersonalNotes] && (
              <Group p="s-16">
                <PreviewSectionHeader
                  title="Personal Notes"
                  subtitle={
                    <Flex flexDirection="column">
                      <Text>Text field</Text>
                      <Text>Personal field, will not be shared with others</Text>
                    </Flex>
                  }
                  iconName="Form"
                />
              </Group>
            )}
          </VStack>
        </VStack>
      )}

      {data?.enable_ai && (
        <VStack pt="s-16">
          <Text pb="s-12" color={Token.color.greyTone50} variant="emphasis2">
            AI Content
          </Text>
          <Group p="s-16">
            <PreviewSectionHeader
              title="Enable AI"
              subtitle="Generates a summary and populates action items after the meeting"
              iconName="Cleaning"
            />
          </Group>
        </VStack>
      )}
    </Widget>
  )
}
