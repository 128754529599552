import React from 'react'
import {
  Badge,
  DataPoint,
  Dropdown,
  Flex,
  H5,
  IconButton,
  Skeleton,
  Tag,
  Text,
  TextSkeleton,
  Token,
  useDropdown,
} from '@revolut/ui-kit'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { MeetingNotesFeedback } from '@src/interfaces/meetings'
import { differenceInMinutes, format, isPast } from 'date-fns'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { deleteMeetingNotesFeedback } from '@src/api/meetings'
import { PermissionTypes } from '@src/store/auth/types'
import { meetingDuration } from '../utils'

interface Props {
  meetingNotes: MeetingNotesFeedback | undefined
  isLoading: boolean
  meetingId?: number
}

const NotesSkeleton = () => (
  <Flex p="s-16" alignItems="center" justifyContent="space-between">
    <Flex gap="s-48" justifyContent="space-between">
      <Flex gap="s-4" flexDirection="column" alignItems="flex-start">
        <Flex gap="s-8" alignItems="center">
          <TextSkeleton size={20} variant="heading3" />
          <TextSkeleton size={14} variant="body2" />
        </Flex>

        <Flex gap="s-8" justifyContent="flex-start">
          <TextSkeleton size={14} variant="body2" />
          <TextSkeleton size={14} variant="body2" />
        </Flex>
      </Flex>

      <Flex gap="s-8" flexDirection="column">
        <Flex gap="s-8" alignItems="center">
          <Skeleton height={24} width={150} />
          <TextSkeleton size={14} variant="body2" />
        </Flex>

        <TextSkeleton size={14} variant="body2" />
      </Flex>
    </Flex>

    <Flex alignItems="center" gap="s-6">
      <TextSkeleton size={14} variant="body2" />
      <TextSkeleton size={14} variant="body2" />
    </Flex>
  </Flex>
)

export const NotesHeader = ({ meetingNotes, isLoading, meetingId }: Props) => {
  const dropdown = useDropdown()

  const confirmationPopup = useConfirmationDialog()
  const showStatusPopup = useShowStatusPopup()
  const canChangeNotes = meetingNotes?.field_options.permissions?.includes(
    PermissionTypes.ChangeOneToOneFeedbackItem,
  )

  const handleDelete = () => {
    confirmationPopup.show({
      showHeader: false,
      body: (
        <H5 color={Token.color.foreground}>
          Are you sure you want to delete this notes?
        </H5>
      ),
      noMessage: 'Cancel',
      yesMessage: 'Confirm',
      onConfirm: async () => {
        if (meetingNotes?.id && meetingId) {
          await deleteMeetingNotesFeedback(meetingId, meetingNotes.id)
          showStatusPopup({
            title: 'Notes deleted',
            status: 'success',
          })
        }
      },
    })
  }

  if (isLoading) {
    return <NotesSkeleton />
  }

  if (!meetingNotes) {
    return null
  }

  return (
    <Flex p="s-16" alignItems="center" justifyContent="space-between">
      <Flex gap="s-48" justifyContent="space-between">
        <Flex flexDirection="column" alignItems="flex-start">
          <DataPoint>
            <DataPoint.Value>
              <Flex alignItems="center" gap="s-4">
                <Text variant="heading3">
                  {format(new Date(meetingNotes.meeting.start), 'MMM d')}
                </Text>
                <Badge bg={Token.color.searchBackground} color={Token.color.grey50}>
                  {isPast(new Date(meetingNotes.meeting.end)) ? 'Previous' : 'Future'}
                </Badge>
              </Flex>
            </DataPoint.Value>
            <DataPoint.Label>
              <Flex alignItems="center" gap="s-4">
                <Text variant="body2">
                  {meetingDuration(meetingNotes.meeting.start, meetingNotes.meeting.end)}
                </Text>
                <Text variant="body2" color={Token.color.grey50}>
                  {differenceInMinutes(
                    new Date(meetingNotes.meeting.start),
                    new Date(meetingNotes.meeting.end),
                  )}
                  min
                </Text>
              </Flex>
            </DataPoint.Label>
          </DataPoint>
        </Flex>

        <DataPoint>
          <DataPoint.Value>
            <Flex gap="s-4" alignItems="center">
              <UserWithAvatar compact {...meetingNotes.recorded_by} />
              <Text variant="heading3">{meetingNotes.recorded_by.full_name}</Text>
            </Flex>
          </DataPoint.Value>
          <DataPoint.Label>
            <Text variant="body2">Notes taken by</Text>
          </DataPoint.Label>
        </DataPoint>
      </Flex>

      <Flex alignItems="center" gap="s-8">
        {meetingNotes.enable_ai ? (
          <Tag color={Token.color.greyTone50} variant="outlined" useIcon="Pencil">
            Manual
          </Tag>
        ) : (
          <Tag color={Token.color.lightBlue} variant="outlined" useIcon="Cleaning">
            AI Transcript
          </Tag>
        )}
        {canChangeNotes && (
          <IconButton
            useIcon="Ellipsis"
            aria-label="Close"
            {...dropdown.getAnchorProps()}
          />
        )}
        <Dropdown {...dropdown.getTargetProps()}>
          <Dropdown.Item
            use="button"
            useIcon="Delete"
            color={Token.color.danger}
            onClick={handleDelete}
          >
            Delete notes
          </Dropdown.Item>
        </Dropdown>
      </Flex>
    </Flex>
  )
}
