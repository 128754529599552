import { GetRequestInterface } from '@src/interfaces'
import { GrowthPlanRelationInterface } from '@src/interfaces/growthPlans'
import { API } from '@src/constants/api'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { api } from '@src/api/index'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const getGrowthPlansRelations = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface) =>
  api.get<GetRequestInterface<GrowthPlanRelationInterface>>(API.GROWTH_PLANS_RELATIONS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

// can be used for both filtering and sorting
export enum GrowthPlansRelationsQueryKeys {
  Id = 'id',
  FullName = 'full_name',
  LatestReviewScore = 'latest_review_score',
  LatestReviewScoreLabel = 'latest_review_score_label',
  TargetSpecialisationId = 'target_specialisation__id',
  TargetSeniorityId = 'target_seniority__id',
  OwnerId = 'owner_id',
  Status = 'status',
}
