import React, { useEffect } from 'react'
import { Action, StatusWidget } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { PageNoAccessWidget } from '@src/features/Errors/components/Page/PageNoAccessWidget'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'
import { ParsableError, parseError } from '@src/features/Errors/parseError'
import { captureError } from '@src/features/Errors/captureError'
import { PageWidgetWrapper } from './PageWidgetWrapper'
import { PageErrorTitle } from './PageErrorTitle'
import { PageErrorFallback } from './PageErrorFallback'

interface PageErrorProps {
  error: ParsableError
}

export const PageError = ({ error }: PageErrorProps) => {
  const parsedError = parseError(error)

  useEffect(() => {
    const responseStatus = error?.response?.status

    if (
      typeof responseStatus === 'number' &&
      responseStatus >= 500 &&
      responseStatus < 600
    ) {
      captureError(error, { tags: { component: 'PageError' } })
    }
  }, [error])

  if (parsedError.subType === 'be_error') {
    return (
      <PageWidgetWrapper>
        <StatusWidget>
          <StatusWidget.Image src="https://assets.revolut.com/assets/3d-images/3D087@2x.png" />
          <StatusWidget.Title>
            <PageErrorTitle error={parsedError} />
          </StatusWidget.Title>
          <StatusWidget.Actions>
            <Action onClick={() => navigateTo(ROUTES.MAIN)}>Go to dashboard</Action>
          </StatusWidget.Actions>
        </StatusWidget>
      </PageWidgetWrapper>
    )
  }

  if (parsedError.type === 'forbidden') {
    return <PageNoAccessWidget />
  }

  if (parsedError.type === 'resource_not_found') {
    return <PageNotFoundWidget />
  }

  return <PageErrorFallback />
}
