import React, { useState } from 'react'
import {
  ActionButton,
  Avatar,
  Button,
  Group,
  Item,
  StatusPopup,
  Text,
  useStatusPopup,
  Link,
  VStack,
} from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import {
  askGoogleWorkspacePermissions,
  askSlackWorkspacePermissions,
} from '@src/api/officeSuiteProvider'
import { CreateEmployeeUploadSource, createEmployeeUpload } from '@src/api/bulkDataImport'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { navigateReplace } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { OnboardingChecklistHeader } from '../components/OnboardingChecklistHeader'
import { pathToUrl } from '@src/utils/router'
import { IntegrationEnablePopup } from '@src/pages/Hub/Integrations/components/IntegrationEnablePopup'
import InputsSkeleton from '@components/Skeletons/InputsSkeleton'
import Form from '@src/features/Form/Form'
import {
  integrationApiFormMap,
  serverNameValidators,
} from '@src/pages/Forms/GoalForm/Form/GoalMetricForm/constants'
import { AxiosError } from 'axios'
import { JIRA_EMAIL_VISIBILITY_LINK } from '@src/constants/externalLinks'
import { ConnectMergeIntegrationButton } from '@src/pages/Hub/Integrations/components/ConnectMergeIntegrationButton'

export const SelectFlow = () => {
  const [pending, setPending] = useState({
    google: false,
    slack: false,
    jira: false,
    bamboohr: false,
  })
  const [intergrationPopupOpen, setIntegrationPopupOpen] = useState(false)

  const statusPopup = useStatusPopup()

  const featureFlags = useSelector(selectFeatureFlags)

  const googleWorkspaceIntegrationEnabled = featureFlags.includes(
    FeatureFlags.GoogleWorkspaceIntegration,
  )
  const slackWorkspaceIntegrationEnabled = featureFlags.includes(
    FeatureFlags.SlackWorkspaceIntegration,
  )
  const bambooHrIntegrationEnabled = featureFlags.includes(FeatureFlags.BambooHR)
  const jiraOauthIntegrationEnabled =
    featureFlags.includes(FeatureFlags.JiraOauthIntegration) &&
    featureFlags.includes(FeatureFlags.ImportEmployeesFromJira)

  if (!window.createEmployeeUploadFromWorkspace) {
    window.createEmployeeUploadFromWorkspace = type => {
      createEmployeeUploadFromWorkspace(type)
    }
  }

  if (!window.showFailureToConnectWorkspace) {
    window.showFailureToConnectWorkspace = error => {
      showFailureToConnectWorkspace(error)
    }
  }

  const showFailureToConnectWorkspace = (error?: AxiosError) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>Failed to import data</StatusPopup.Title>
        <StatusPopup.Description>
          {getStringMessageFromError(error)}
        </StatusPopup.Description>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide} variant="secondary" elevated>
            Close
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  const createEmployeeUploadFromWorkspace = (type: CreateEmployeeUploadSource) => {
    statusPopup.show(
      <StatusPopup variant="loading" preventUserClose>
        <StatusPopup.Title>Importing data</StatusPopup.Title>
      </StatusPopup>,
    )

    createEmployeeUpload(type)
      .then(response => {
        statusPopup.hide()
        navigateReplace(
          pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION, {
            id: response.data.id,
          }),
        )
      })
      .catch(error => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to import data</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} variant="secondary" elevated>
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })
  }

  const onConnectGoogleWorkspace = () => {
    setPending(prev => ({ ...prev, google: true }))
    askGoogleWorkspacePermissions().finally(() => {
      setPending(prev => ({ ...prev, google: false }))
    })
  }

  const onConnectSlackWorkspace = () => {
    setPending(prev => ({ ...prev, slack: true }))
    askSlackWorkspacePermissions().finally(() => {
      setPending(prev => ({ ...prev, slack: false }))
    })
  }

  const onConnectJiraWorkspace = () => {
    setPending(prev => ({ ...prev, jira: true }))
    setIntegrationPopupOpen(true)
  }

  const onConnectBambooWorkspace = () => {
    setPending(prev => ({ ...prev, bamboo: true }))
  }

  if (
    !googleWorkspaceIntegrationEnabled &&
    !slackWorkspaceIntegrationEnabled &&
    !jiraOauthIntegrationEnabled
  ) {
    return (
      <InternalRedirect
        to={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.IMPORT}
      />
    )
  }

  return (
    <PageWrapper>
      <OnboardingChecklistHeader
        title="Import employees"
        backUrl={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT}
      />
      <PageBody>
        <Group>
          <Item
            use="button"
            onClick={() =>
              navigateReplace(
                ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.IMPORT,
              )
            }
          >
            <Item.Avatar>
              <Avatar useIcon="Upload" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>File upload</Item.Title>
              <Item.Description>
                Upload a .xlsx or .csv file to import you employees
              </Item.Description>
            </Item.Content>
          </Item>

          {googleWorkspaceIntegrationEnabled ? (
            <Item>
              <Item.Avatar>
                <Avatar useIcon="LogoGoogle|image" bg="background" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Google Workspace</Item.Title>
                <Item.Description>
                  Import employees from your google workspace
                </Item.Description>
              </Item.Content>
              <Item.Side>
                <ActionButton
                  onClick={() => onConnectGoogleWorkspace()}
                  pending={pending.google}
                >
                  Connect
                </ActionButton>
              </Item.Side>
            </Item>
          ) : null}

          {slackWorkspaceIntegrationEnabled ? (
            <Item>
              <Item.Avatar>
                <Avatar
                  image="https://assets.revolut.com/assets/apps/Slack.png"
                  bg="background"
                />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Slack</Item.Title>
                <Item.Description>
                  Import employees from your slack workspace
                </Item.Description>
              </Item.Content>
              <Item.Side>
                <ActionButton
                  onClick={() => onConnectSlackWorkspace()}
                  pending={pending.slack}
                >
                  Connect
                </ActionButton>
              </Item.Side>
            </Item>
          ) : null}

          {bambooHrIntegrationEnabled ? (
            <Item>
              <Item.Avatar>
                <Avatar
                  image="https://assets.revolut.com/revoluters/hub-icons/bamboo-40@2x.png"
                  bg="background"
                />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>BambooHR</Item.Title>
                <Item.Description>
                  Import employees from BambooHR workspace
                </Item.Description>
              </Item.Content>
              <Item.Side>
                <ConnectMergeIntegrationButton
                  integration="bamboohr"
                  onClick={() => onConnectBambooWorkspace()}
                  onSuccess={() => createEmployeeUploadFromWorkspace('bamboohr')}
                >
                  Connect
                </ConnectMergeIntegrationButton>
              </Item.Side>
            </Item>
          ) : null}

          {jiraOauthIntegrationEnabled ? (
            <>
              <Item>
                <Item.Avatar>
                  <Avatar useIcon="Jira|image" bg="background" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>Jira</Item.Title>
                  <Item.Description>
                    <VStack gap="s-4">
                      <Text>Import employees from your Jira workspace</Text>
                      <Text>
                        All Jira users need to set their email visibility to <b>Anyone</b>
                        , as described{' '}
                        <Link
                          use="a"
                          rel="noreferrer noopener"
                          target="_blank"
                          href={JIRA_EMAIL_VISIBILITY_LINK}
                        >
                          here
                        </Link>
                      </Text>
                    </VStack>
                  </Item.Description>
                </Item.Content>
                <Item.Side>
                  <ActionButton
                    onClick={() => onConnectJiraWorkspace()}
                    pending={pending.jira}
                  >
                    Connect
                  </ActionButton>
                </Item.Side>
              </Item>
              <Form
                loadingState={<InputsSkeleton />}
                api={integrationApiFormMap.roadmap}
                validator={serverNameValidators.roadmap}
                forceParams={{ id: '1' }}
              >
                <IntegrationEnablePopup
                  isOpen={intergrationPopupOpen}
                  onClose={() => {
                    setPending(prev => ({ ...prev, jira: false }))
                    setIntegrationPopupOpen(false)
                  }}
                  type="jira"
                />
              </Form>
            </>
          ) : null}
        </Group>
      </PageBody>
    </PageWrapper>
  )
}
