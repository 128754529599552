import React from 'react'
import { Box, Text, Token, Widget } from '@revolut/ui-kit'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  createRequisitionTitleColumn,
  requisitionHeadcountColumn,
} from '@src/constants/columns/requisition'
import { seniorityNameRequisitionsColumn } from '@src/constants/columns/seniority'
import { teamNameColumn } from '@src/constants/columns/team'
import { lineManagerColumn } from '@src/constants/columns/employee'
import {
  createConnectedRequisitionActionColumn,
  RemoveConnectedRequisitionHandler,
} from '@src/constants/columns/connectedRequisitions'

const getRow = (
  onRemoveConnectedRequisition: RemoveConnectedRequisitionHandler,
): RowInterface<RequisitionInterface> => ({
  cells: [
    {
      ...createRequisitionTitleColumn(false),
      title: 'Requisitions',
      width: 140,
    },
    {
      ...seniorityNameRequisitionsColumn,
      width: 140,
    },
    {
      ...teamNameColumn,
      width: 130,
    },
    {
      ...lineManagerColumn,
      title: 'Hiring manager',
      width: 130,
    },
    {
      ...requisitionHeadcountColumn,
      width: 130,
      title: 'Headcount',
    },
    {
      ...createConnectedRequisitionActionColumn(onRemoveConnectedRequisition),
      width: 100,
    },
  ],
})

interface RequisitionTableProps {
  loading: boolean
  requisitions?: RequisitionInterface[]
  onRemoveConnectedRequisition: RemoveConnectedRequisitionHandler
}

export const RequisitionTable = ({
  loading,
  requisitions,
  onRemoveConnectedRequisition,
}: RequisitionTableProps) => {
  if (!requisitions?.length) {
    return null
  }
  return (
    <Box py="s-16">
      <Widget>
        <Box p="s-16">
          <Text color={Token.color.greyTone50} variant="emphasis2">
            Connected requisitions
          </Text>
        </Box>
        <AdjustableTable
          count={requisitions.length}
          data={requisitions}
          dataType="Requisitions"
          hideCount
          loading={loading}
          name={TableNames.JobPostingRequisitions}
          row={getRow(onRemoveConnectedRequisition)}
          useWindowScroll
        />
      </Widget>
    </Box>
  )
}
