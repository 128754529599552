import React, { Suspense } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'
import { ActionButton, HStack, Token } from '@revolut/ui-kit'
import { getQueryResultExportUrl, queryRunsTableRequests } from '@src/api/dataAnalytics'
import {
  queriesActionsHistoryTableColumn,
  queriesDateRunHistoryTableColumn,
  queriesDurationHistoryTableColumn,
  queriesParametersHistoryTableColumn,
  queriesRunByHistoryTableColumn,
  queriesRowsHistoryTableColumn,
  queriesStatusHistoryTableColumn,
  queriesExportsHistoryTableColumn,
} from '@src/constants/columns/dataAnalytics'
import { TableNames } from '@src/constants/table'
import { RowInterface, Stats } from '@src/interfaces/data'
import {
  RunQueryResponseInterface,
  QueryRunStatuses,
} from '@src/interfaces/dataAnalytics'
import { PageBody } from '@src/components/Page/PageBody'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable, useTableReturnType } from '@components/TableV2/hooks'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { saveFile } from '@src/utils'
import Tooltip from '@components/Tooltip/Tooltip'

const ExportButtonComponent = React.lazy(() => import('./components/ExportButton'))

const getRow = (
  queryId: string,
  table: useTableReturnType<RunQueryResponseInterface, Stats, {}>,
): RowInterface<RunQueryResponseInterface> => ({
  cells: [
    {
      ...queriesDateRunHistoryTableColumn,
      width: 100,
    },
    {
      ...queriesRunByHistoryTableColumn,
      width: 100,
    },
    {
      ...queriesDurationHistoryTableColumn,
      width: 100,
    },
    {
      ...queriesRowsHistoryTableColumn,
      width: 100,
    },
    {
      ...queriesParametersHistoryTableColumn,
      width: 150,
    },
    {
      ...queriesStatusHistoryTableColumn,
      width: 50,
    },
    {
      ...queriesActionsHistoryTableColumn,
      width: 50,
      insert: ({ data }) =>
        data.status === QueryRunStatuses.Success && !data.result?.expired ? (
          <Suspense fallback={null}>
            <ExportButtonComponent
              iconOnly
              runId={data.id}
              queryId={queryId}
              onClickCallback={table.refresh}
            />
          </Suspense>
        ) : null,
    },
    {
      ...queriesExportsHistoryTableColumn,
      width: 80,
      insert: ({ data }) => {
        if (!data.result?.exports.length) {
          return null
        }

        const exportData = data.result?.exports?.[0]

        const fileName = (url: string) => (url ? url.slice(url.lastIndexOf('/') + 1) : '')

        return !!data.result && exportData ? (
          <Suspense fallback={null}>
            <HStack space="s-16">
              {data.result?.exports.map((exp, i) => (
                <Tooltip
                  key={i}
                  placement="left"
                  text="You’ll be able to download the report when it’s done"
                  hide={!!exp.url}
                >
                  <ActionButton
                    disabled={!exp.url}
                    size="xs"
                    onClick={() => {
                      getQueryResultExportUrl({
                        queryId,
                        runId: data.id,
                        fileType: exp.format,
                      }).then(res => {
                        if (res.data?.url) {
                          saveFile(res.data.url, fileName(exp.url))
                        }
                      })
                    }}
                  >
                    {exp.format || 'ccc'}
                  </ActionButton>
                </Tooltip>
              ))}
            </HStack>
          </Suspense>
        ) : null
      },
    },
  ],
})

export interface OptionInterface {
  id: string
  name: string
  fields: string[]
}

export const HistoryTable = connect(() => {
  const params = useParams<{ id: string }>()
  const table = useTable<RunQueryResponseInterface>({
    getItems: queryRunsTableRequests(params.id),
  })

  return (
    <PageBody maxWidth={Token.breakpoint.xxl}>
      <Table.Widget>
        <Table.Widget.Table>
          <AdjustableTable<RunQueryResponseInterface>
            dataType="Runs"
            name={TableNames.ReportingAppQueriesHistoricalRuns}
            emptyState={<EmptyTableRaw title="Runs will appear here" />}
            row={getRow(params.id, table)}
            useWindowScroll
            {...table}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </PageBody>
  )
})
