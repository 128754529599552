import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/features/JobPostingFlow/types'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { navigateTo } from '@src/actions/RouterActions'

export const getBackUrl = (
  params: JobPostingFlowParams,
  locationState: JobPostingLocationState,
) => {
  if (params.id) {
    return pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
      id: params.id,
      specId: locationState?.specialisationId || params?.specialisationId,
    })
  }
  if (locationState?.requisitionId) {
    return pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, {
      id: locationState.requisitionId,
    })
  }
  if (locationState?.specialisationId) {
    return pathToUrl(ROUTES.FORMS.SPECIALISATIONS.POSTINGS, {
      id: locationState.specialisationId,
    })
  }
  return pathToUrl(ROUTES.RECRUITMENT.JOBS.JOB_POSTINGS)
}

export const useNavigateToJobPostingPath = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const customHiringProcesses = (featureFlags ?? []).includes(
    FeatureFlags.CustomHiringProcesses,
  )
  return ({ id, specialisationId, requisitionId, name }: JobPostingLocationState) => {
    const params = {
      id,
      specId: specialisationId,
    }
    const state = customHiringProcesses
      ? {
          requisitionId,
          specialisationId,
          name,
        }
      : undefined
    const path = customHiringProcesses
      ? ROUTES.FORMS.JOB_POSTING_FLOW.DETAILS
      : ROUTES.FORMS.JOB_POSTING.GENERAL
    navigateTo(pathToUrl(path, params), state)
  }
}

export type DetailsSidebars = 'connectRequisitions'
