import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Table from '@src/components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { useTable } from '@components/TableV2/hooks'
import { getEmployeeMeetings, OneToOneMeetingsQueryKeys } from '@src/api/meetings'
import { TableNames } from '@src/constants/table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { getRow } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsTable/Row'
import { EmployeeInterface } from '@src/interfaces/employees'
import { SORT_DIRECTION } from '@src/interfaces/data'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { ReviewEventsCalendar } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/ReviewEvents/ReviewEventsCalendar'
import { oneToOneModel } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/models/oneToOneModel'
import { SchedulePopup } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/SchedulePopup/SchedulePopup'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { selectUser } from '@src/store/auth/selectors'

enum ViewMode {
  TableView = 'Table',
  CalendarView = 'Calendar',
}

const initialSortBy = [
  {
    sortBy: OneToOneMeetingsQueryKeys.EmployeeRelationType,
    direction: SORT_DIRECTION.ASC,
  },
]

interface Props {
  employee: EmployeeInterface
}

export const MeetingsTable = ({ employee }: Props) => {
  const { tabBar: ViewModeTabBar, currentTab: viewModeCurrentTab } = useTabBarSwitcher({
    tabs: [ViewMode.TableView, ViewMode.CalendarView],
    useIcons: {
      [ViewMode.TableView]: '16/ListBullet',
      [ViewMode.CalendarView]: 'CalendarWeek',
    },
    defaultTab: ViewMode.TableView,
    highlightSelected: false,
  })
  const [selectedMeeting, setSelectedMeeting] = useState<OneToOneMeeting | null>(null)
  const currentUser = useSelector(selectUser)

  const table = useTable(
    { getItems: params => getEmployeeMeetings(employee.id, params) },
    [],
    initialSortBy,
  )
  const row = useMemo(() => getRow(employee, currentUser), [employee.id, currentUser.id])

  const onRowClick = (meeting: OneToOneMeeting) => {
    const isManagedByCurrentUser = oneToOneModel.isMeetingManagedBy(currentUser, meeting)
    const isScheduled = oneToOneModel.isScheduled(meeting)
    const isParticipant = oneToOneModel.isMeetingParticipant(currentUser, meeting)

    if (isScheduled && isParticipant) {
      navigateTo(
        pathToUrl(
          ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.ONE_TO_ONE.SUMMARY,
          {
            meetingId: meeting.id,
            employeeId: meeting.employee.id,
            managerId: meeting.manager.id,
          },
        ),
      )
    } else if (isManagedByCurrentUser) {
      setSelectedMeeting(meeting)
    }
  }

  const closeSchedulePopup = () => {
    setSelectedMeeting(null)
  }

  return (
    <>
      <Table.Widget>
        <Table.Widget.Filters>
          <Table.Search
            placeholder="Search by employee name"
            onFilter={table.onFilterChange}
            variant="compact"
            disabled={viewModeCurrentTab === ViewMode.CalendarView}
          />
          {ViewModeTabBar}
        </Table.Widget.Filters>
        <Table.Widget.Table>
          {viewModeCurrentTab === ViewMode.TableView && (
            <AdjustableTable<OneToOneMeeting>
              name={TableNames.EmployeeMeetings}
              row={row}
              hideCount
              {...table}
              emptyState={<EmptyTableRaw title="1:1 meetings will appear here." />}
              onRowClick={onRowClick}
            />
          )}
          {viewModeCurrentTab === ViewMode.CalendarView && (
            <ReviewEventsCalendar employee={employee} />
          )}
        </Table.Widget.Table>
      </Table.Widget>
      {selectedMeeting !== null && (
        <SchedulePopup
          isOpen
          meeting={selectedMeeting}
          handleClose={closeSchedulePopup}
        />
      )}
    </>
  )
}
