import { useGetMergeConnections } from '@src/api/integrations'
import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { Text, Avatar, Spacer, Token, HStack, VStack, Box } from '@revolut/ui-kit'
import { OnboardingIntro } from '@src/components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@src/components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroTips } from '@src/components/OnboardingIntro/OnboardingIntroTips'
import { DisconnectMergeIntegrationItem } from '@src/pages/Hub/Integrations/components/DisconnectMergeIntegrationItem'
import { ConnectMergeIntegrationButton } from '@src/pages/Hub/Integrations/components/ConnectMergeIntegrationButton'

export const BambooHRIntegration = () => {
  return <BambooHRForm />
}

const BambooHRForm = () => {
  const { data: integrations, refetch } = useGetMergeConnections('bamboohr')

  const integration = integrations?.results?.[0]
  const isConnected = Boolean(integration)

  return (
    <PageWrapper>
      <PageHeader
        title={
          <VStack maxWidth={{ all: '100%', md: Token.breakpoint.md }}>
            <HStack align="start">
              <VStack>
                <Text>BambooHR</Text>
                {isConnected && (
                  <Text variant="emphasis1" color={Token.color.success}>
                    Connected
                  </Text>
                )}
                <Text color={Token.color.greyTone50} variant="body2">
                  HRIS integrations
                </Text>
              </VStack>
              <Spacer />
              <Avatar
                image="https://assets.revolut.com/revoluters/hub-icons/bamboo-40@2x.png"
                size={56}
              >
                {isConnected ? (
                  <Avatar.Badge useIcon="Check" bg={Token.color.success} />
                ) : null}
              </Avatar>
            </HStack>
          </VStack>
        }
        backUrl={pathToUrl(ROUTES.FEATURES.INTEGRATIONS)}
      />

      {!isConnected && (
        <Box>
          <ConnectMergeIntegrationButton
            integration="bamboohr"
            onSuccess={() => refetch()}
            useIcon="Link"
            variant="accent"
          >
            Enable
          </ConnectMergeIntegrationButton>
        </Box>
      )}

      <PageBody mb="s-64" mt="s-16" gap="s-16">
        <OnboardingIntro>
          <OnboardingIntroTextWidget
            title={
              <FormattedMessage
                id="integrations.bamboo.intro.title"
                defaultMessage="About integration"
              />
            }
          >
            <p>
              <FormattedMessage
                id="integrations.bamboo.intro.text"
                defaultMessage="Easily import employees information from BambooHR"
              />
            </p>
          </OnboardingIntroTextWidget>
        </OnboardingIntro>
        <OnboardingIntroTips
          title={
            <FormattedMessage
              id="integrations.bamboo.intro.tips.title"
              defaultMessage="How to use"
            />
          }
          items={[
            {
              title: (
                <FormattedMessage
                  id="integrations.bamboo.intro.tips.integrate.title"
                  defaultMessage="Connect your BambooHR account"
                />
              ),
              icon: 'Link',
              text: (
                <FormattedMessage
                  id="integrations.bamboo.intro.tips.integrate.text"
                  defaultMessage="You will be asked to specify your BambooHR login information in this step. Please note, you have to be BambooHR admin in order to connect to Revolut People."
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="integrations.bamboo.intro.tips.mapping.title"
                  defaultMessage="Review field mapping"
                />
              ),
              icon: 'Gear',
              text: (
                <FormattedMessage
                  id="integrations.bamboo.intro.tips.mapping.text"
                  defaultMessage="After connecting to BambooHR, review field mapping between BambooHR and Revolut People. We will map all required fields automatically. You can map any additional fields during integration process or later in Employee Settings."
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="integrations.bamboo.intro.tips.sync.title"
                  defaultMessage="Sync employees"
                />
              ),
              icon: 'ArrowExchange',
              text: (
                <FormattedMessage
                  id="integrations.bamboo.intro.tips.sync.text"
                  defaultMessage="By default your employees will sync with Revolut People automatically daily. You can switch between auto-sync and manual sync in Employee Settings."
                />
              ),
            },
          ]}
        />
        {isConnected && !!integration && (
          <DisconnectMergeIntegrationItem
            title="BambooHR"
            integrationId={integration.id}
            onSuccess={() => refetch()}
          />
        )}
      </PageBody>
    </PageWrapper>
  )
}
