import React, { useEffect } from 'react'
import { Box } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import PageLoading from '@components/PageLoading/PageLoading'
import { useQuery } from '@src/utils/queryParamsHooks'
import { pathToUrl } from '@src/utils/router'
import { useGetAuthentication } from '@src/api/auth'
import { setAuthenticatedAction } from '@src/store/auth/actions'
import { Authenticated } from '@src/store/auth/constants'
import { useDispatch } from 'react-redux'

export const LoginSSO = () => {
  const { query } = useQuery<{ token?: string; authenticated?: Authenticated }>()
  const dispatch = useDispatch()
  const { data, isError, isLoading } = useGetAuthentication(query.token)

  useEffect(() => {
    if (
      query?.authenticated === Authenticated.not_authenticated ||
      isError ||
      data?.authenticated === Authenticated.not_authenticated
    ) {
      navigateTo(pathToUrl(ROUTES.LOGIN.MAIN), {
        error: 'Authentication failed. Please check your credentials and try again.',
      })
    }
    if (data?.authenticated) {
      // TODO: REVCOR-3140 - do we need to set cookie / workspace here? (maybe overrideAPIMapWithTenant ?)
      dispatch(setAuthenticatedAction(data.authenticated === Authenticated.authenticated))
      goBack(ROUTES.MAIN)
    }
  }, [query, data, isLoading, isError])

  return (
    <Box height="100vh" width="100vw">
      <PageLoading />
    </Box>
  )
}
