import React from 'react'
import { Button, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { ParsableError, parseError } from './parseError'
import { ActionErrorTitle } from './components/ActionErrorTitle'
import { getMessageFromApiError } from '@src/store/notifications/actions'

interface UseErrorPopupShowParams {
  error: ParsableError
  /** Fallback title in case of an unknown unhandled error. Do not use `Something went wrong`, use user friendly message instead, e.g. `Could not archive team` */
  fallbackTitle: string
  fallbackDescription?: string
}

export const useErrorPopup = () => {
  const statusPopup = useStatusPopup()

  return {
    show: ({ error, fallbackTitle, fallbackDescription }: UseErrorPopupShowParams) => {
      const parsedError = parseError(error)
      const apiDescription = getMessageFromApiError(error)
      const description = apiDescription || fallbackDescription

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>
            <ActionErrorTitle error={parsedError} fallbackTitle={fallbackTitle} />
          </StatusPopup.Title>
          {description && (
            <StatusPopup.Description>{description}</StatusPopup.Description>
          )}
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide} variant="secondary">
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    },
    hide: statusPopup.hide,
  }
}
