import React from 'react'
import { Box, MoreBar, Subheader } from '@revolut/ui-kit'
import { useGetJobPostingRequisitions } from '@src/api/requisitions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { RequisitionTable } from './RequisitionTable'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { RemoveConnectedRequisitionHandler } from '@src/constants/columns/connectedRequisitions'

interface ConnectedRequisitionsProps {
  requisitions: RequisitionInterface[]
  onConnectRequisitions: VoidFunction
  onRemoveConnectedRequisition: RemoveConnectedRequisitionHandler
}

export const ConnectedRequisitions = ({
  requisitions,
  onConnectRequisitions,
  onRemoveConnectedRequisition,
}: ConnectedRequisitionsProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { data, isLoading } = useGetJobPostingRequisitions(values.id)
  return (
    <Box>
      <Subheader>
        <Subheader.Title>Connect to requisition</Subheader.Title>
      </Subheader>
      <Subheader variant="nested">
        <Subheader.Title>
          Requisitions is a request for a new employee to fill a vacant position in an
          organisation. You can select existing or create a new one.
        </Subheader.Title>
      </Subheader>
      <MoreBar>
        <MoreBar.Action
          useIcon="Plug"
          onClick={onConnectRequisitions}
          disabled={!values.specialisation?.id}
        >
          Connect requisitions
        </MoreBar.Action>
      </MoreBar>
      <RequisitionTable
        loading={isLoading}
        requisitions={[...requisitions, ...(data?.results || [])]}
        onRemoveConnectedRequisition={onRemoveConnectedRequisition}
      />
    </Box>
  )
}
